import { styled } from '@linaria/react';

function IconSnippets(props: React.HTMLAttributes<SVGElement>) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="m18 16 4-4-4-4" />
      <path d="m6 8-4 4 4 4" />
      <path d="m14.5 4-5 16" />
    </Svg>
  );
}

const Svg = styled.svg`
  display: block;
  width: 1rem;
  height: 1.5rem;
`;

export default IconSnippets;
