import type { Category } from '@/types/content.types';

// A hardcoded list of categories.
// Sorted in preferential order. Certain contexts might only
// include a subset (eg. mobile nav), and it should be taken
// from the top.
export const CATEGORIES: Array<Category> = [
  { name: 'CSS', slug: 'css', translate: 'no' },
  { name: 'React', slug: 'react', translate: 'no' },
  { name: 'Animation', slug: 'animation', translate: 'yes' },
  { name: 'JavaScript', slug: 'javascript', translate: 'no' },
  { name: 'Career', slug: 'career', translate: 'yes' },
  { name: 'Next.js', slug: 'nextjs', translate: 'no' },
  { name: 'General', slug: 'blog', translate: 'yes' },
] as const;

export const SNIPPET_CATEGORIES: Array<Category> = [
  { name: 'JavaScript', slug: 'javascript', translate: 'no' },
  { name: 'HTML & CSS', slug: 'html', translate: 'yes' },
  {
    name: 'React Component',
    slug: 'react-components',
    translate: 'no',
  },
  { name: 'React Hook', slug: 'react-hooks', translate: 'no' },
];

// This category is used when an article has no category (eg. meta
// posts).
export const DEFAULT_CATEGORY_SLUG = 'blog';

export type CategorySlug =
  | (typeof CATEGORIES)[number]['slug']
  | typeof DEFAULT_CATEGORY_SLUG;

export type SnippetCategorySlug =
  (typeof SNIPPET_CATEGORIES)[number]['slug'];

export const getFormattedName = (
  categorySlug: CategorySlug | SnippetCategorySlug
): string => {
  // We have one pseudo-category, 'blog', for "meta" posts, and stuff that doesn't fit anywhere else. It isn't included in CATEGORIES because it isn't listed as a category.
  if (categorySlug === 'blog') {
    return '';
  }

  const ALL_CATS = [...CATEGORIES, ...SNIPPET_CATEGORIES];

  const category = ALL_CATS.find((cat) => cat.slug === categorySlug);

  return category?.name || '';
};
