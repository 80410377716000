// NOTE: Going forwards, I should use ColorModeContainer directly. This feels like too silly an abstraction. There are 100+ callsites though, so I'm not worrying about it for now.

import ColorModeContainer, {
  type Props as ColorModeProps,
} from '@/components/ColorModeContainer';

type Props = Omit<ColorModeProps, 'colorMode'>;

function Paper(props: Props) {
  return <ColorModeContainer colorMode="light" {...props} />;
}

export default Paper;
