export const COURSES = [
  {
    title: 'CSS for JavaScript Developers',
    slug: 'css-for-js',
    href: 'https://css-for-js.dev/',
  },
  {
    title: 'The Joy of React',
    slug: 'joy-of-react',
    href: 'https://joyofreact.com',
  },
] as const;
