import { styled } from '@linaria/react';

function IconOperators(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <Wrapper {...props} aria-hidden="true">
      &&
    </Wrapper>
  );
}

const Wrapper = styled.div`
  align-self: baseline;
  position: relative;
  display: flex;
  align-items: center;
  width: 1rem;
  height: 1.5rem;
  font-family: var(--font-family-mono);
  letter-spacing: -0.5px;
  font-weight: var(--font-weight-medium);
  /* Optical alignment */
  transform: translateY(-1px);
`;

export default IconOperators;
