'use client';

import React from 'react';
import { useSpring, animated } from 'react-spring';

import { IconProps } from './Icon.types';

function IconCourses({ size = 20, isBooped }: IconProps) {
  const config = {
    tension: 350,
    friction: 10,
    clamp: isBooped,
  };

  const spring = useSpring({
    d: isBooped
      ? `
        M 11.562 5
        a 0.5 0.5 0 0 1 .876 0
        L15.39 8.87
        a1 1 0 0 0 1.516.294
        L19.183 5.5
        a 0.5 0.5 0 0 1 .798.519
        l -0.834 10.246
        a1 1 0 0 1-.956.734
        H5.81
        a1 1 0 0 1-.957-.734
        L 4.02 6.02
        a 0.5 0.5 0 0 1 .798-.519
        l 2.276 3.664
        a1 1 0 0 0 1.516-.294z
      `
      : `
        M 11.562 3.266
        a 0.5 0.5 0 0 1 .876 0
        L15.39 8.87
        a1 1 0 0 0 1.516.294
        L21.183 5.5
        a 0.5 0.5 0 0 1 .798.519
        l -2.834 10.246
        a1 1 0 0 1-.956.734
        H5.81
        a1 1 0 0 1-.957-.734
        L 2.02 6.02
        a  0.5 0.5 0 0 1 .798-.519
        l 4.276 3.664
        a1 1 0 0 0 1.516-.294z
      `,
    config,
  });

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size / 16 + 'rem'}
      height={size / 16 + 'rem'}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
    >
      <animated.path {...spring} />
      <path d="M5 21h14" />
    </svg>
  );
}

export default IconCourses;
