import * as React from 'react';

import { LIGHT_COLORS, DARK_COLORS } from '@/constants';

import { UserPreferencesContext } from '@/components/UserPreferencesProvider';

export function useTintedBackground(isOpen: boolean) {
  const { colorMode } = React.useContext(UserPreferencesContext);
  const colorModeRef = React.useRef(colorMode);
  colorModeRef.current = colorMode;

  React.useEffect(() => {
    // We don't need to do this in Dark Mode, it looks fine as-is
    if (!isOpen || colorMode === 'dark') {
      return;
    }

    const newBackgroundColor = LIGHT_COLORS['--color-cloud-300'];

    document.documentElement.style.setProperty(
      '--color-background',
      newBackgroundColor
    );

    return () => {
      document.documentElement.style.setProperty(
        '--color-background',
        colorModeRef.current === 'light'
          ? LIGHT_COLORS['--color-background']
          : DARK_COLORS['--color-background']
      );
    };
  }, [colorMode, isOpen]);
}
