export const VIEWBOX_SIZE = 24;
const HAMBURGER_LINE_WIDTH = 18;
const HAMBURGER_X_INSET = (VIEWBOX_SIZE - HAMBURGER_LINE_WIDTH) / 2;
const HAMBURGER_Y_INSET = HAMBURGER_X_INSET + 2;
const CLOSE_INSET = HAMBURGER_X_INSET + 2;

export type IconStatus = 'closed' | 'opening' | 'open' | 'closing';

export function getFirstLineProps(
  iconStatus: IconStatus,
  isBooped: boolean
) {
  switch (iconStatus) {
    case 'closed': {
      const y = isBooped ? HAMBURGER_Y_INSET + 2 : HAMBURGER_Y_INSET;

      return {
        x1: HAMBURGER_X_INSET,
        y1: y,
        x2: VIEWBOX_SIZE - HAMBURGER_X_INSET,
        y2: y,
      };
    }
    case 'opening':
    case 'closing':
      return {
        x1: HAMBURGER_X_INSET,
        y1: VIEWBOX_SIZE / 2,
        x2: VIEWBOX_SIZE - HAMBURGER_X_INSET,
        y2: VIEWBOX_SIZE / 2,
      };
    case 'open':
      if (isBooped) {
        return {
          x1: CLOSE_INSET,
          y1: CLOSE_INSET + 2,
          x2: VIEWBOX_SIZE - CLOSE_INSET,
          y2: VIEWBOX_SIZE - CLOSE_INSET - 2,
        };
      }
      return {
        x1: CLOSE_INSET,
        y1: CLOSE_INSET,
        x2: VIEWBOX_SIZE - CLOSE_INSET,
        y2: VIEWBOX_SIZE - CLOSE_INSET,
      };
  }
}

export function getSecondLineProps() {
  // The second one is super simple: it doesn't move during the close, and while open, it doesn't even exist.
  return {
    x1: HAMBURGER_X_INSET,
    y1: VIEWBOX_SIZE / 2,
    x2: VIEWBOX_SIZE - HAMBURGER_X_INSET,
    y2: VIEWBOX_SIZE / 2,
  };
}
export function getThirdLineProps(
  iconStatus: IconStatus,
  isBooped: boolean
) {
  switch (iconStatus) {
    case 'closed': {
      const y = isBooped
        ? VIEWBOX_SIZE - HAMBURGER_Y_INSET - 2
        : VIEWBOX_SIZE - HAMBURGER_Y_INSET;
      return {
        x1: HAMBURGER_X_INSET,
        y1: y,
        x2: VIEWBOX_SIZE - HAMBURGER_X_INSET,
        y2: y,
      };
    }
    case 'opening':
    case 'closing':
      return {
        x1: HAMBURGER_X_INSET,
        y1: VIEWBOX_SIZE / 2,
        x2: VIEWBOX_SIZE - HAMBURGER_X_INSET,
        y2: VIEWBOX_SIZE / 2,
      };
    case 'open':
      if (isBooped) {
        return {
          x1: CLOSE_INSET,
          y1: VIEWBOX_SIZE - CLOSE_INSET - 2,
          x2: VIEWBOX_SIZE - CLOSE_INSET,
          y2: CLOSE_INSET + 2,
        };
      }
      return {
        x1: CLOSE_INSET,
        y1: VIEWBOX_SIZE - CLOSE_INSET,
        x2: VIEWBOX_SIZE - CLOSE_INSET,
        y2: CLOSE_INSET,
      };
  }
}
