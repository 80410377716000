import * as React from 'react';
import { styled } from '@linaria/react';
import FocusLock from 'react-focus-lock';

import InPortal from '@/components/InPortal';
import OverlayBase, { Props as OverlayProps } from './OverlayBase';

type DelegatedOverlayProps = Omit<
  OverlayProps,
  'isOpen' | 'handleDismiss'
>;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
  portalId?: string;
  skipPortal?: boolean;
  returnFocus?: boolean;
  allowScroll?: boolean;
  overlay?: React.ComponentType<OverlayProps> | null;
  overlayProps?: DelegatedOverlayProps;
  dismissDuration?: number;
  enableDismissAfter?: number;
  handleDismiss: () => void;
  children: React.ReactNode;
}

function ModalBase({
  isOpen,
  portalId,
  skipPortal,
  returnFocus = false,
  allowScroll = false,
  overlay: Overlay = OverlayBase,
  overlayProps = {},
  dismissDuration = 1000,
  enableDismissAfter = 0,
  handleDismiss,
  children,
  ...delegated
}: Props) {
  const [isRendered, setIsRendered] = React.useState<boolean>(isOpen);

  const lastOpenedTimestamp = React.useRef<number>(0);
  React.useEffect(() => {
    if (isOpen) {
      lastOpenedTimestamp.current = Date.now();
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (isOpen) {
      setIsRendered(true);
      return;
    }

    const timeoutId = setTimeout(() => {
      setIsRendered(false);
    }, dismissDuration);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isOpen, isRendered, dismissDuration]);

  const handleDismissRef = React.useRef(handleDismiss);
  handleDismissRef.current = handleDismiss;

  React.useEffect(() => {
    if (!isOpen) {
      return;
    }

    function handleKeyDown(ev: KeyboardEvent) {
      if (ev.key === 'Escape') {
        // In some cases, modals can't be dismissed for a short duration after opening, since doing so would create bugs or jank.
        const timeSinceOpen =
          Date.now() - lastOpenedTimestamp.current;
        const wasOpenedTooRecently =
          timeSinceOpen < enableDismissAfter;

        if (!wasOpenedTooRecently) {
          handleDismissRef.current();
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, enableDismissAfter]);

  if (!isRendered) {
    return null;
  }

  const elem = (
    <Wrapper {...delegated} data-disable-document-scroll={false}>
      {Overlay && (
        <Overlay
          {...overlayProps}
          isOpen={isOpen}
          handleDismiss={handleDismiss}
        />
      )}

      <FocusLock
        // Most modals should return focus, but there are exceptions. For example, ToastySubscribe is opened from a prompt which no longer exists when the modal is dismissed, so we manage that manually elsewhere.
        returnFocus={returnFocus}
        // This modal lingers for as long as the exit animation takes to complete, but we don't want to wait that long before restoring focus to whatever was focused before the modal was opened!
        disabled={!isOpen}
      >
        <ChildWrapper>{children}</ChildWrapper>
      </FocusLock>
    </Wrapper>
  );

  return skipPortal ? (
    elem
  ) : (
    <InPortal id={portalId}>{elem}</InPortal>
  );
}

const Wrapper = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  isolation: isolate;
  pointer-events: none;
`;

const ChildWrapper = styled.div`
  position: absolute;
  inset: 0;
  width: fit-content;
  height: fit-content;
  margin: auto;
  pointer-events: auto;
`;

export default ModalBase;
