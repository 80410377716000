import * as React from 'react';
import { styled } from '@linaria/react';

import Link from '@/components/Link';

function JoshLinks({
  handleClickLink,
}: {
  handleClickLink: () => void;
}) {
  return (
    <Wrapper>
      <BackgroundCloud
        viewBox="0 0 788 265"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M681.771 38.1111C693.771 38.1111 688.271 -2.38884 759.771 0.111155C770.228 0.476802 779.338 1.54837 787.271 3.11155V264.111H0.770948C0.770948 264.111 -9.72906 229.611 46.7709 192.611C103.271 155.611 148.271 182.611 153.271 181.611C158.271 180.611 153.771 139.111 230.771 123.111C307.771 107.111 322.771 138.111 332.271 136.111C341.771 134.111 336.771 87.6111 405.271 74.1111C473.771 60.6112 497.771 89.1112 505.771 88.1111C513.771 87.1111 510.271 37.1111 574.271 21.6111C638.271 6.11115 669.771 38.1111 681.771 38.1111Z"
          fill="var(--color-cloud-300)"
        />
      </BackgroundCloud>

      <picture>
        <source
          type="image/webp"
          srcSet="/images/josh/very-happy-light.webp"
        />
        <StyledJosh src="/images/josh/very-happy-light.png" />
      </picture>

      <CloudBorder
        viewBox="0 0 740 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M230 74.9998C221.5 71.9995 190.5 22.4998 69 18.4998C45.6329 17.7305 25.6317 19.3469 8.56594 22.4598C4.15502 23.2644 0 19.9389 0 15.4552V108H740V60.9843C740 65.0822 736.496 68.3004 732.408 68.01C729.819 67.8261 727.184 67.6558 724.5 67.4998C595.5 59.9998 582 95 573.5 94.9998C565 94.9995 532 58.9998 406 47.4998C280 35.9998 238.5 78 230 74.9998Z"
          fill="white"
        />
      </CloudBorder>

      <LinkList>
        <li>
          <StyledLink
            skipViewTransitions
            onClick={handleClickLink}
            href="/about-josh"
          >
            About Josh
          </StyledLink>
        </li>
        <li>
          <StyledLink
            skipViewTransitions
            onClick={handleClickLink}
            href="/contact"
          >
            Contact
          </StyledLink>
        </li>
      </LinkList>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  --shadow-color: hsl(214deg 30% 60% / 0.2);
  container-type: inline-size;

  position: relative;
  margin-top: 16px;
  border-radius: 4px;
  /* trim corners and Josh */
  overflow: hidden;
  overflow: clip;
  aspect-ratio: 18 / 10;
  max-height: 250px;
  background: linear-gradient(
    to top,
    var(--color-sky-to) 30%,
    var(--color-sky-from) 100%
  );
  box-shadow:
    inset 0px 1px 2px var(--shadow-color),
    inset 0px 2px 4px var(--shadow-color),
    inset 0px 4px 8px var(--shadow-color);
`;

const BackgroundCloud = styled.svg`
  min-width: 475px;
  width: 85%;
  max-width: revert;
  position: absolute;
  right: -1px;
  bottom: 0;
`;

const CloudBorder = styled.svg`
  /* Prevent an awkward sub-pixel gap on the left by insetting by half a pixel. */
  width: calc(100% + 1px);
  max-width: revert;
  position: absolute;
  left: -0.5px;
  right: -0.5px;
  bottom: -2px;
`;

const StyledJosh = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 32%;
  max-width: 150px;
  transform: translateY(28%) rotate(20deg);
`;

const LinkList = styled.ul`
  position: absolute;
  right: 8px;
  bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 0;
  padding-bottom: 5%;
  list-style-type: none;
`;

const StyledLink = styled(Link)`
  display: block;
  padding: 4px;
  padding-right: 8px;
  color: inherit;
  text-decoration: none;
  font-size: 0.875rem;
  text-align: right;
`;

export default JoshLinks;
