/*
  NOTE: This component is both used inside the desktop Dropdown navigation as well as the mobile drawer navigation. Be sure to test both UIs before making any changes.
*/
import * as React from 'react';
import { styled } from '@linaria/react';

import { CATEGORIES } from '@/helpers/category.helpers';

import Link from '@/components/Link';
import IconReact from '@/components/Icons/Cats/IconReact';
import IconCSS from '@/components/Icons/Cats/IconCSS';
import IconAnimation from '@/components/Icons/Cats/IconAnimation';
import IconJS from '@/components/Icons/Cats/IconJS';
import IconCareer from '@/components/Icons/Cats/IconCareer';
import IconBlog from '@/components/Icons/Cats/IconBlog';
import type { IconProps } from '@/components/Icons/Icon.types';

import type { Category } from '@/types/content.types';

type CategorySlug = (typeof CATEGORIES)[number]['slug'];

const ICONS_BY_CATEGORY: Record<CategorySlug, React.ComponentType> = {
  react: IconReact,
  css: IconCSS,
  animation: IconAnimation,
  javascript: IconJS,
  career: IconCareer,
  blog: IconBlog,
};

const CATEGORIES_MENU_ITEMS = CATEGORIES.filter(
  (cat: Category) => cat.slug !== 'nextjs'
).map((cat: Category) => {
  return {
    ...cat,
    icon: ICONS_BY_CATEGORY[cat.slug],
  };
});

function CategoryGrid({ onClick }: { onClick?: () => void }) {
  return (
    <Wrapper>
      {CATEGORIES_MENU_ITEMS.map((category) => {
        const Icon = category.icon as React.ComponentType<IconProps>;

        if (!Icon) {
          throw new Error(
            'missing icon for category: ' + category.slug
          );
        }

        return (
          <li key={category.slug}>
            <CatLink href={`/${category.slug}`} onClick={onClick}>
              <Icon size={16} style={{ flexShrink: 0 }} />
              <span translate={category.translate}>
                {category.name}
              </span>
            </CatLink>
          </li>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.ol`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(7.5rem, 1fr));
  list-style-type: none;
  margin: 0;
  padding: 8px;

  /*
    On larger devices (eg. iPad Mini), it looks weird to have 4+ columns. Limit it to 3 columns.
  */
  @container (min-width: 25rem) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const CatLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  border-radius: 4px;
  color: inherit;
  font-size: 0.875rem;

  /* Desktop-specific styles */
  --hover-bg: var(--color-cloud-300);

  html[data-color-mode='dark'] & {
    --hover-bg: var(--color-cloud-400);
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      --icon-outline-color: var(--hover-bg);
      background: var(--hover-bg);
    }

    /*
      This is a subtle thing: if the next item in the grid is hovered, the hover backdrop shows up *above* the focus outline, since it comes later in the DOM. Prevent this by promoting the focused link to use positioned layout, so that it sits atop the hover backdrop.
    */
    &:focus {
      position: relative;
    }
  }
`;

export default CategoryGrid;
