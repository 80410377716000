import { styled } from '@linaria/react';

function IconShadows(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <Wrapper {...props}>
      <BackSquare />
      <FrontSquare />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 1rem;
  height: 1.5rem;
`;

const Square = styled.div`
  position: relative;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 100px;
`;

const FrontSquare = styled(Square)`
  background: var(--icon-background-color, var(--color-background));
  border: 0.09375rem solid black;
  outline: 0.0625rem solid
    var(--icon-outline-color, var(--color-background));
`;
const BackSquare = styled(Square)`
  position: absolute;
  top: 0.4375rem;
  left: 0.1875rem;
  background: repeating-linear-gradient(
    45deg,
    var(--color-gray-800) 0px 1px,
    transparent 1px 2px
  );
`;

export default IconShadows;
