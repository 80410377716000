import * as React from 'react';
import { styled } from '@linaria/react';
import { useSpring, animated } from 'react-spring';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  Icon: React.ComponentType<any>;
  label: string;
  initialIsOpen?: boolean;
  children: React.ReactNode;
}

export const ExpandableGroupContext =
  React.createContext<boolean>(false);

function ExpandableGroup({
  Icon,
  label,
  initialIsOpen = false,
  children,
  ...delegated
}: Props) {
  const [isOpen, setIsOpen] = React.useState(initialIsOpen);
  const [height, setHeight] = React.useState<number | 'auto'>(
    initialIsOpen ? 'auto' : 0
  );
  const [isBooped, setIsBooped] = React.useState(false);

  const contentsRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!contentsRef.current) {
      return;
    }

    const contents = contentsRef.current;

    const boundingRect = contents.getBoundingClientRect();
    const newHeight = isOpen ? boundingRect.height : 0;

    setHeight(newHeight);
  }, [isOpen]);

  const chevronSpring = useSpring({
    points: isOpen ? '6 9 12 15 18 9' : '5 12 12 12 19 12',
    config: {
      tension: 300,
      friction: 20,
      clamp: !isOpen,
    },
  });

  // Annoyingly, this hook sometimes logs a warning:
  // “Got NaN while animating”
  // This happens because `height` can be "auto", and React Spring doesn't know how to deal with that. Fortunately, everything works perfectly, so we can ignore this warning.
  const contentStyle = useSpring({
    height,
    onRest: () => {
      if (isOpen) {
        setHeight('auto');
      }
    },
    config: {
      tension: 300,
      friction: 20,
      clamp: !isOpen,
    },
  });

  return (
    <ExpandableGroupContext.Provider value={isOpen}>
      <Toggle
        {...delegated}
        onClick={() => {
          setIsOpen(!isOpen);
          setIsBooped(true);

          window.setTimeout(() => {
            setIsBooped(false);
          }, 150);
        }}
      >
        <Icon isBooped={isBooped} />
        <Label>{label}</Label>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <animated.polyline {...chevronSpring} />
        </svg>
      </Toggle>
      <Contents
        data-is-open={String(isOpen)}
        inert={!isOpen}
        style={contentStyle}
      >
        <div ref={contentsRef}>{children}</div>
      </Contents>
    </ExpandableGroupContext.Provider>
  );
}

const Toggle = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  gap: 12px;
  padding: 12px 8px;
`;

const Label = styled.span`
  flex: 1;
`;

const Contents = styled(animated.div)`
  --shadow-color: hsl(200deg 30% 70% / 0.2);
  /* The “Shadow Palette Generator” icon needs to know the background color for its cutout effect */
  --icon-background-color: var(--color-cloud-300);
  --icon-outline-color: var(--color-cloud-300);

  position: relative;
  padding-left: 24px;
  background: var(--color-cloud-300);
  border-radius: 6px;
  overflow: hidden;
  overflow: clip;
  container-type: inline-size;
  box-shadow:
    inset 0px 1px 2px var(--shadow-color),
    inset 0px 2px 4px var(--shadow-color),
    inset 0px 4px 8px var(--shadow-color);

  /*
  TODO: When “calc-size” lands in iOS Safari and Android Chrome, get rid of the manual height calculation above, and swap for this elegant CSS:

  height: 0;
  transition: height 300ms;

  &[data-is-open='true'] {
      height: auto;
      height: calc-size(auto);
    }

  */
`;

export default ExpandableGroup;
