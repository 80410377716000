import * as React from 'react';
import { styled } from '@linaria/react';

import { USE_HAMBURGER_MENU_BELOW } from '@/constants';
import useOnResize from '@/hooks/use-on-resize';

import ModalBase from '@/components/ModalBase';
import Paper from '@/components/Paper';
import Link from '@/components/Link';
import IconLatest from '@/components/Icons/IconLatest';
import IconCategories from '@/components/Icons/IconCategories';
import IconCourses from '@/components/Icons/IconCourses';
import IconGoodies from '@/components/Icons/IconGoodies';
import IconNewsletter from '@/components/Icons/IconNewsletter';
import CategoryGrid from '@/components/NavigationContents/CategoryGrid';
import CoursesListMobile from '@/components/NavigationContents/CoursesListMobile';
import GoodiesGrid from '@/components/NavigationContents/GoodiesGrid';

import ExpandableGroup from './ExpandableGroup';
import JoshLinks from './JoshLinks';
import { useTintedBackground } from './MobileMenu.helpers';

interface Props {
  isOpen: boolean;
  handleDismiss: () => void;
  handleClickLink: () => void;
}

function MobileMenu({
  isOpen,
  handleDismiss,
  handleClickLink,
}: Props) {
  const [pressedLink, setPressedLink] = React.useState<string | null>(
    null
  );

  useTintedBackground(isOpen);

  // If the user opens the menu on mobile and then increases the window size, we want to automatically dismiss it when we get above the breakpoint.
  // We need to do this in JS, rather than with a media query, because of the fact that open modals change `--color-background`.
  useOnResize(() => {
    if (!isOpen) {
      return;
    }

    const windowWidthInRems = window.innerWidth / 16;

    if (windowWidthInRems > USE_HAMBURGER_MENU_BELOW) {
      handleDismiss();
    }
  }, 350);

  return (
    <ModalBase
      returnFocus
      isOpen={isOpen}
      portalId="mobile-menu-portal"
      dismissDuration={300}
      overlay={null}
      handleDismiss={handleDismiss}
    >
      <Modal
        style={{
          opacity: isOpen ? 1 : 0,
          transitionDelay: isOpen ? '0ms' : '200ms',
        }}
        data-disable-document-scroll={isOpen}
      >
        <ModalContent
          style={{
            opacity: isOpen ? 1 : 0,
            transitionDelay: isOpen ? '200ms' : '0ms',
          }}
        >
          <List>
            <ListItem>
              <NavLink
                skipViewTransitions
                href="/latest"
                onClick={handleClickLink}
              >
                <IconLatest /> Latest
              </NavLink>
            </ListItem>
            <ListItem>
              <ExpandableGroup
                Icon={IconCategories}
                label="Categories"
                initialIsOpen={true}
              >
                <CategoryGrid onClick={handleClickLink} />
              </ExpandableGroup>
            </ListItem>
            <ListItem>
              <ExpandableGroup Icon={IconCourses} label="Courses">
                <CoursesListMobile />
              </ExpandableGroup>
            </ListItem>
            <ListItem>
              <ExpandableGroup Icon={IconGoodies} label="Goodies">
                <GoodiesGrid onClick={handleClickLink} />
              </ExpandableGroup>
            </ListItem>
            <ListItem>
              <NavLink
                skipViewTransitions
                href="/subscribe"
                onClick={handleClickLink}
                onPointerDown={() => setPressedLink('newsletter')}
                onMouseUp={() => setPressedLink(null)}
                onMouseLeave={() => setPressedLink(null)}
                onTouchEnd={() => setPressedLink(null)}
              >
                <IconNewsletter
                  youveGotMail={pressedLink === 'newsletter'}
                />
                World-Famous Newsletter!
              </NavLink>
            </ListItem>
          </List>
          <Flex />
          <JoshLinks handleClickLink={handleClickLink} />
          <SubFooter>
            <LegalLinksList>
              <LegalListItem>
                <LegalLink href="https://courses.joshwcomeau.com/terms">
                  Terms of Use
                </LegalLink>
              </LegalListItem>
              <LegalListItem>
                <LegalLink href="https://courses.joshwcomeau.com/privacy">
                  Privacy Policy
                </LegalLink>
              </LegalListItem>
              <LegalListItem>
                <LegalLink href="https://courses.joshwcomeau.com/code-of-conduct">
                  Code of Conduct
                </LegalLink>
              </LegalListItem>
            </LegalLinksList>
          </SubFooter>
        </ModalContent>
      </Modal>
    </ModalBase>
  );
}

const Modal = styled(Paper)`
  --padding: 8px;
  position: fixed;
  inset: var(--padding);
  top: 5rem;
  border-radius: 6px;
  padding: var(--padding);
  padding-top: 0;
  /* Don't use --color-adaptive-white here, too hard to color-match with the other colors. */
  background: white;
  transition: opacity 300ms;
  box-shadow:
    0px 2px 4px hsl(0deg 0% 0% / 0.25),
    0px 4px 8px hsl(0deg 0% 0% / 0.2),
    0px 8px 16px hsl(0deg 0% 0% / 0.15),
    0px 16px 32px hsl(0deg 0% 0% / 0.12);
  overflow: auto;
  animation: menuEnter 400ms cubic-bezier(0.17, 0.67, 0.51, 1);
  will-change: transform;

  @keyframes menuEnter {
    from {
      transform: translateX(25%);
      opacity: 0;
    }
  }
`;

const ModalContent = styled.nav`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  animation: fadeIn 300ms backwards;
  animation-delay: 200ms;
  transition: opacity 300ms;
`;

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`;

const ListItem = styled.li`
  padding: 4px 0;
  border-bottom: 1px solid var(--color-gray-200);

  &:not(:last-child) {
  }
`;

const Flex = styled.div`
  flex: 1;
`;

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 8px;
  text-decoration: none;
  color: inherit;
`;

const SubFooter = styled.footer`
  margin: calc(var(--padding) * -1);
  margin-top: var(--padding);
`;

const LegalLinksList = styled.ul`
  display: flex;
  justify-content: space-evenly;
  padding: 0;
  list-style-type: none;
  font-size: 0.75rem;
`;
const LegalListItem = styled.li`
  position: relative;
`;

const LegalLink = styled(Link)`
  display: block;
  padding: 0.5rem;
  color: inherit;
  font-weight: var(--font-weight-medium);
  text-decoration: none;
  text-align: center;
`;

export default MobileMenu;
