/*
  Based on ArrowRight from Feather Icons, except it can be booped to point towards something. Meant to be used as a bullet in a list.
*/
import { styled } from '@linaria/react';
import { useSpring, animated } from 'react-spring';

import useBoopMinimal from '@/hooks/use-boop-minimal';

const SPRING_CONFIG = {
  tension: 300,
  friction: 16,
};

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
  isHovering?: boolean;
}

function IconArrowBullet({
  size = 20,
  isHovering = false,
  ...delegated
}: Props) {
  const isBooped = useBoopMinimal(isHovering);

  const lineProps = useSpring({
    x2: isBooped ? 23 : 18,
    config: SPRING_CONFIG,
  });
  const polyLineProps = useSpring({
    points: isBooped ? '17 6 24 12 17 18' : '12 5 19 12 12 19',
    config: SPRING_CONFIG,
  });

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={size / 16 + 'rem'}
      height={size / 16 + 'rem'}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...delegated}
    >
      <animated.line x1="5" y1="12" y2="12" {...lineProps} />
      <animated.polyline {...polyLineProps} />
    </Svg>
  );
}

const Svg = styled.svg`
  display: block;
  overflow: visible;
`;

export default IconArrowBullet;
