import * as React from 'react';
import ReactDOM from 'react-dom';

interface Props {
  id?: string;
  children: React.ReactNode;
  parent?: HTMLElement;
}

const InPortal = ({ id, children, parent }: Props) => {
  const [hostElement, setHostElement] =
    React.useState<Element | null>(null);

  React.useEffect(() => {
    const actualParent = parent || document.body;

    const elm = id
      ? document.querySelector(`#${id}`)
      : document.createElement('div');

    if (!elm) {
      return;
    }

    setHostElement(elm);

    if (!id) {
      actualParent.appendChild(elm);
    }

    return () => {
      if (!id) {
        // In localhost, this error often masks other errors when a hot reload occurs. We'll wrap it in a try/catch to prevent this.
        try {
          actualParent.removeChild(elm);
        } catch (err) {
          // Swallow it.
        }
      }
    };
  }, [parent, id]);

  if (!hostElement) {
    return null;
  }

  return ReactDOM.createPortal(children, hostElement);
};

export default React.memo(InPortal);
