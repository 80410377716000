import * as React from 'react';

import { throttle } from '@/utils';

const useOnResize = (callback: Function, throttleBy = 500) => {
  let isMounted = React.useRef(true);

  const callbackRef = React.useRef(callback);
  callbackRef.current = callback;

  const handleResize = React.useMemo(() => {
    return throttle((...args: any) => {
      if (isMounted.current) {
        callbackRef.current(...args);
      }
    }, throttleBy);
  }, [throttleBy]);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  React.useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return null;
};

export default useOnResize;
