import { styled } from '@linaria/react';

function IconGradient(props: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <Wrapper {...props}>
      <Col style={{ '--color': `hsl(200deg 50% 0% / 1)` }} />
      <Col style={{ '--color': `hsl(200deg 50% 15% / 0.8)` }} />
      <Col style={{ '--color': `hsl(200deg 50% 30% / 0.6)` }} />
      <Col style={{ '--color': `hsl(200deg 50% 50% / 0.3)` }} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1rem;
  height: 1.5rem;
`;

const Col = styled.div`
  width: 0.1875rem;
  height: 1rem;
  background: var(--color);
  border-radius: 0.09375rem;
`;

export default IconGradient;
