import * as React from 'react';
import { styled } from '@linaria/react';

import CategoryIconBase from './CategoryIconBase';
import { IconProps } from '../Icon.types';

function IconAnimation(props: IconProps) {
  const id = React.useId();
  const maskId = `mask-${id}`.replace(/:/g, '');

  return (
    <WrappedCategoryIconBase {...props}>
      <defs>
        <mask id={maskId}>
          <polyline
            fill="#FFFFFF"
            points="-10 0, 24 0, 24 24, 0 24"
          />
        </mask>
      </defs>
      <g
        mask={`url(#${maskId})`}
        style={{ transform: 'translateX(4px)' }}
      >
        <path d="M17.7 7.7a2.5 2.5 0 1 1 1.8 4.3H-12" />
        <path d="M9.6 4.6A2 2 0 1 1 11 8H-12" />
        <path d="M12.6 19.4A2 2 0 1 0 14 16H-12" />
      </g>
    </WrappedCategoryIconBase>
  );
}

const WrappedCategoryIconBase = styled(CategoryIconBase)`
  transform: translateX(2px) rotateY(180deg);
  overflow: visible;
`;

export default IconAnimation;
