/*
  NOTE: This component is both used inside the desktop Dropdown navigation as well as the mobile drawer navigation. Be sure to test both UIs before making any changes.
*/
import * as React from 'react';
import { styled } from '@linaria/react';

import { USE_HAMBURGER_MENU_BELOW } from '@/constants';

import Link from '@/components/Link';
import IconGradient from '@/components/Icons/Goods/IconGradient';
import IconShadows from '@/components/Icons/Goods/IconShadows';
import IconOperators from '@/components/Icons/Goods/IconOperatorsActualText';
import IconPortfolioBook from '@/components/Icons/Goods/IconPortfolioBook';
import IconSnippets from '@/components/Icons/Goods/IconSnippets';
import IconNewsletter from '@/components/Icons/IconNewsletter';

interface Props {
  onClick?: () => void;
  withShortNames?: boolean;
  includeNewsletter?: boolean;
}

const GOODIES_MENU_ITEMS = [
  {
    slug: 'gradient-generator',
    name: 'Gradient Generator',
    Icon: IconGradient,
  },
  {
    slug: 'shadow-palette',
    name: 'Shadow Palette Generator',
    shortName: 'Shadow Generator',
    Icon: IconShadows,
  },
  {
    slug: 'operator-lookup',
    name: 'Operator Lookup',
    Icon: IconOperators,
  },
  { slug: 'snippets', name: 'Snippets', Icon: IconSnippets },
  {
    slug: 'effective-portfolio',
    name: 'Building an Effective Dev Portfolio',
    Icon: IconPortfolioBook,
  },
];

function GoodiesGrid({
  onClick,
  withShortNames,
  includeNewsletter = false,
}: Props) {
  const [isHoveringNewsletter, setIsHoveringNewsletter] =
    React.useState(false);

  return (
    <Wrapper>
      {GOODIES_MENU_ITEMS.map(({ slug, name, shortName, Icon }) => {
        return (
          <li key={slug}>
            <GoodieLink
              skipViewTransitions
              href={`/${slug}`}
              onClick={onClick}
            >
              {Icon && <StyledIcon as={Icon} />}
              {withShortNames ? shortName ?? name : name}
            </GoodieLink>
          </li>
        );
      })}
      {includeNewsletter && (
        <li>
          <GoodieLink
            skipViewTransitions
            href={`/subscribe`}
            onClick={onClick}
            onMouseEnter={() => {
              setIsHoveringNewsletter(true);
            }}
            onMouseLeave={() => {
              setIsHoveringNewsletter(false);
            }}
          >
            <IconNewsletter
              width={16}
              height={24}
              style={{ flexShrink: 0 }}
              youveGotMail={isHoveringNewsletter}
            />
            World-Famous Developer Newsletter
          </GoodieLink>
        </li>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.ol`
  display: grid;
  /* On desktop, render 2 columns */
  grid-template-columns: 1fr 1fr;
  list-style-type: none;
  margin: 0;
  padding: 8px;

  /* On mobile, stack in a single column. */
  @media (max-width: 27.5rem) {
    grid-template-columns: 1fr;
  }

  /* On larger phones and tablets, use a dynamic number of columns */
  @media (max-width: ${USE_HAMBURGER_MENU_BELOW}rem) {
    grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  }
`;

const GoodieLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 8px;
  color: inherit;
  font-size: 0.875rem;
  line-height: 1.5rem;
  border-radius: 4px;

  /* When stacked in a single column, add a border between list items. */
  @media (max-width: 27.5rem) {
    ${Wrapper} li:not(:last-child) & {
      border-bottom: 0.5px solid hsl(210deg 50% 60% / 0.25);
    }
  }

  /* Desktop stuff */
  @media (hover: hover) and (pointer: fine) {
    --hover-bg: var(--color-cloud-300);

    html[data-color-mode='dark'] & {
      --hover-bg: var(--color-cloud-400);
    }

    &:hover {
      --icon-outline-color: var(--hover-bg);
      background: var(--hover-bg);
    }

    /*
    This is a subtle thing: if the next item in the grid is hovered, the hover backdrop shows up *above* the focus outline, since it comes later in the DOM. Prevent this by promoting the focused link to use positioned layout, so that it sits atop the hover backdrop.
  */
    &:focus {
      position: relative;
    }
  }
`;

const StyledIcon = styled.div`
  flex-shrink: 0;
`;

export default GoodiesGrid;
