'use client';

import React from 'react';
import { useSpring, animated } from 'react-spring';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
  isBooped?: boolean;
}

function IconGoodies({ size = 20, isBooped }: Props) {
  const config = {
    tension: 350,
    friction: 10,
    clamp: isBooped,
  };
  const svgStyle = useSpring({
    transform: isBooped ? `rotate(-20deg)` : 'rotate(0deg)',
    transformOrigin: 'center center',
    config,
  });

  return (
    <animated.svg
      viewBox="0 0 24 24"
      width={size / 16 + 'rem'}
      height={size / 16 + 'rem'}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      xmlns="http://www.w3.org/2000/svg"
      style={svgStyle}
    >
      {/* Bonbon oval */}
      <path d="m9.5 7.5-2 2a4.95 4.95 0 1 0 7 7l2-2a4.95 4.95 0 1 0-7-7Z" />

      {/* Stripes */}
      <path d="M14 6.5v10" />
      <path d="M10 7.5v10" />

      {/* Top twisty wrapper */}
      {/* Tried to animate it, but it just looked funny. */}
      <path
        d={`
          m16 7 1 -5 1.37.68
          A3 3 0 0 0 19.7 3
          H21
          v1.3
          c0 0.5 0.1 0.92 0.32 -2
          L22 7
          l-5 1
        `}
      />
      {/* Bottom twisty wrapper */}
      <path d="m8 17-1 5-1.37-.68A3 3 0 0 0 4.3 21H3v-1.3a3 3 0 0 0-.32-1.33L2 17l5-1" />
    </animated.svg>
  );
}

export default IconGoodies;
