import * as React from 'react';
import { styled } from '@linaria/react';

export interface Props {
  isOpen: boolean;
  enterDuration?: number;
  exitDuration?: number;
  enterDelay?: number;
  exitDelay?: number;
  openOpacity?: number;
  handleDismiss: () => void;
}

function OverlayBase({
  isOpen,
  enterDuration = 1000,
  enterDelay = 0,
  exitDuration = 600,
  exitDelay = 400,
  openOpacity = 1,
  handleDismiss,
}: Props) {
  return (
    <Wrapper
      onClick={handleDismiss}
      style={{
        '--opacity': isOpen ? openOpacity : 0,
        '--transition-duration': isOpen
          ? `${enterDuration}ms`
          : `${exitDuration}ms`,
        '--transition-delay': isOpen
          ? `${enterDelay}ms`
          : `${exitDelay}ms`,
        pointerEvents: isOpen ? 'auto' : 'none',
      }}
    />
  );
}

const Wrapper = styled.div`
  position: absolute;
  inset: 0;
  background-color: var(--color-muted-background);
  opacity: var(--opacity);
  transition: opacity var(--transition-duration);
  transition-delay: var(--transition-delay);
  pointer-events: auto;

  animation: fadeFromTransparent var(--transition-duration)
    var(--transition-delay) backwards;
`;

export default OverlayBase;
