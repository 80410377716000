import CategoryIconBase from './CategoryIconBase';
import { IconProps } from '../Icon.types';

function IconBlog(props: IconProps) {
  return (
    <CategoryIconBase {...props}>
      <path d="M2 6h4" />
      <path d="M2 10h4" />
      <path d="M2 14h4" />
      <path d="M2 18h4" />
      <rect width="16" height="20" x="4" y="2" rx="2" />
      <path d="M9.5 8h5" />
      <path d="M9.5 12H16" />
      <path d="M9.5 16H14" />
    </CategoryIconBase>
  );
}

export default IconBlog;
