import * as React from 'react';

interface ExtraOptions {
  triggerRef?: React.RefObject<HTMLElement>;
  rebindDependency?: any;
}

export default function useOnClickOutside(
  ref: React.MutableRefObject<HTMLElement | null>,
  handler: (event: MouseEvent | TouchEvent) => void,
  { triggerRef, rebindDependency }: ExtraOptions = {
    triggerRef: { current: null },
  }
) {
  React.useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      const target = event.target as Node;

      // Do nothing if clicking ref's element or descendant elements
      if (!ref.current || ref.current.contains(target)) {
        return;
      }

      // Often, this hook is used with a dropdown that can be toggled by clicking a trigger. That trigger exists outside the `ref`, but we still want to ignore clicks on the trigger. Otherwise, the dropdown will be closed and opened as part of the same process.
      if (triggerRef?.current?.contains(target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('click', listener, { capture: true });
    document.addEventListener('touchstart', listener, {
      capture: true,
    });

    return () => {
      document.removeEventListener('click', listener, {
        capture: true,
      });
      document.removeEventListener('touchstart', listener, {
        capture: true,
      });
    };
  }, [ref, triggerRef, handler, rebindDependency]);
}
