import React from 'react';
import { useSpring, animated } from 'react-spring';
import { styled } from '@linaria/react';

interface Props extends React.SVGProps<SVGSVGElement> {
  youveGotMail?: boolean;
  mailFlagColor?: string;
  size?: number;
}

function IconNewsletter({
  youveGotMail = false,
  mailFlagColor = 'hsl(340deg 100% 50%)',
  size = 20,
  ...delegated
}: Props) {
  const mailFlagProps = useSpring({
    points: youveGotMail ? '15,9 15,2 18,2' : '15,9 18,9 18,11',
    config: {
      tension: 300,
      friction: 20,
      clamp: !youveGotMail,
    },
  });

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size / 16 + 'rem'}
      height={size / 16 + 'rem'}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      {...delegated}
    >
      <path d="M22 17a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V9.5C2 7 4 5 6.5 5H18c2.2 0 4 1.8 4 4v8Z" />
      <path d="M6.5 5C9 5 11 7 11 9.5V17a2 2 0 0 1-2 2v0" />
      <line x1="6" x2="7" y1="10" y2="10" />
      <MailFlag
        {...mailFlagProps}
        stroke={youveGotMail ? mailFlagColor : 'currentColor'}
      />
    </svg>
  );
}

const MailFlag = styled(animated.polyline)`
  transition: stroke 400ms;
`;

export default IconNewsletter;
