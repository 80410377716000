import { IconProps } from '../Icon.types';

function CategoryIconBase({
  size = 20,
  children,
  ...delegated
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size / 16 + 'rem'}
      height={size / 16 + 'rem'}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...delegated}
    >
      {children}
    </svg>
  );
}

export default CategoryIconBase;
