'use client';

import React from 'react';
import { useSpring, animated } from 'react-spring';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number;
  isBooped?: boolean;
}

const INSET = 3;

function IconCategories({ size = 20, isBooped }: Props) {
  const config = {
    tension: 350,
    friction: 10,
    clamp: isBooped,
  };
  const topStyle = useSpring({
    transform: `translateY(${isBooped ? INSET : 0}px)`,
    config,
  });
  const leftStyle = useSpring({
    transform: `translateX(${isBooped ? INSET : 0}px)`,
    config,
  });
  const rightStyle = useSpring({
    transform: `translateX(${isBooped ? -INSET : 0}px)`,
    config,
  });
  const bottomStyle = useSpring({
    transform: `translateY(${isBooped ? -INSET : 0}px)`,
    config,
  });

  return (
    <svg
      viewBox="0 0 24 24"
      width={size / 16 + 'rem'}
      height={size / 16 + 'rem'}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      xmlns="http://www.w3.org/2000/svg"
    >
      <animated.path
        d="m12 2 3.5 3.5L12 9 8.5 5.5 12 2Z"
        style={topStyle}
      />
      <animated.path
        d="M5.5 8.5 9 12l-3.5 3.5L2 12l3.5-3.5Z"
        style={leftStyle}
      />
      <animated.path
        d="M18.5 8.5 22 12l-3.5 3.5L15 12l3.5-3.5Z"
        style={rightStyle}
      />
      <animated.path
        d="m12 15 3.5 3.5L12 22l-3.5-3.5L12 15Z"
        style={bottomStyle}
      />
    </svg>
  );
}

export default IconCategories;
